import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { RouteComponentProps } from '@reach/router';

import FormRender from '../components/forms/form-render';
import SEO from '../components/seo';
import PublicLayout from '../layout/public-layout';
import { IFormSchema, IFormDataSchema, FormStatus } from '../types/form';
import formSchema from '../__mocks__/form/playground-schema.mock.json';
import { createFormStateFromSchema, FORM_DEBUG_QUERY_KEY, DEFAULT_FORM_DATA } from '../utils/form';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0px 1.0875rem 1.45rem;
`;

const Form = ({ location }: RouteComponentProps) => {
  const qs = location ? parse(location.search) : {};
  const isDebug = qs[FORM_DEBUG_QUERY_KEY] === 'true';

  const [formData, setFormData] = useState<IFormDataSchema>();

  useEffect(() => {
    setTimeout(() => {
      setFormData(DEFAULT_FORM_DATA);
    }, 100);
  }, []);

  const formState = createFormStateFromSchema(formSchema as IFormSchema);
  const formStatus = FormStatus.InProgress;

  const isReady = !!formSchema && !!formData && !!formState && !!formStatus;

  return (
    <PublicLayout>
      <SEO title="Form" />
      <Container>
        <FormRender
          schema={formSchema as IFormSchema}
          initialData={formData}
          initialFormState={formState}
          formStatus={formStatus}
          isSubmitted={false}
          isLoading={false}
          isReady={isReady}
          isSaving={false}
          errorMessage={''}
          confirmationMessage={'<h1>Thank you<h1>'}
          termsOfService={'<h1>Sample Terms of Service</h1>'}
          isDebug={isDebug}
          onSubmit={(formData, formState) => {
            console.log('onSubmit', formData, formState);
          }}
          onSaveForm={(formData, formState) => {
            console.log('onSaveForm', formData, formState);
          }}
          onConfirmationClose={() => {
            console.log('onConfirmationClose');
          }}
        />
      </Container>
    </PublicLayout>
  );
};

export default Form;
